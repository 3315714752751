export const revealVariantsHeader = {
  visible: {
    opacity: 1,
    y: 0,
    transition: {
      type: "spring",
      stiffness: 200,
      damping: 60,
      mass: 1,
      delay: 1,
    },
  },
  hidden: { opacity: 0 },
  exit: {
    opacity: 1,
    y: 0,
    transition: {
      type: "spring",
      stiffness: 200,
      damping: 60,
      mass: 1,
      delay: 0,
    },
  },
};

export const container = {
  hidden: { opacity: 0, y: 0 },
  visible: { opacity: 1, y: 0, transition: { staggerChildren: 0.025 } },
  exit: {
    opacity: 0,
    y: 200,
    transition: { type: "spring", stiffness: 200, damping: 60, mass: 1 },
  },
};

export const listItem = {
  hidden: { opacity: 1, y: 100 },
  visible: {
    opacity: 1,
    y: 0,
    transition: { type: "spring", stiffness: 200, damping: 60, mass: 1 },
  },
  exit: {
    opacity: 0,
    y: 100,
    transition: { type: "spring", stiffness: 200, damping: 60, mass: 1 },
  },
};

export const wrapper = {
  hidden: { opacity: 0 },
  show: { opacity: 1 },
  exit: { opacity: 0 },
};

export const imageVariants = {
  hover: {
    opacity: 1,
    x: 0,
    transition: {
      type: "spring",
      stiffness: 200,
      damping: 60,
      mass: 1,
      delay: 0.3,
    },
  },
  initial: {
    opacity: 0,
    x: 0,
    transition: {
      duration: 0.1,
      type: "spring",
      stiffness: 200,
      damping: 60,
      mass: 1,
    },
  },
};

export const logoVariants = {
  hover: {
    x: 64,
    opacity: 1,
    transition: { type: "spring", stiffness: 200, damping: 60, mass: 1 },
  },
  initial: {
    x: 0,
    opacity: 1,
    transition: {
      type: "spring",
      stiffness: 200,
      damping: 60,
      mass: 1,
    },
  },
};

export const revealVariants = {
  visible: {
    opacity: 1,
    y: 0,
    transition: { type: "spring", stiffness: 200, damping: 60, mass: 1 },
  },
  hidden: { opacity: 0, y: 0 },
};
