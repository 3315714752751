import { motion } from "framer-motion";
import Background from "./Background";
import Link from "../../components/base/Link";
import {
  revealVariants,
  container,
  listItem,
  wrapper,
} from "../../data/animations";

const Contact = () => {
  const heroTitle = [
    { text: "LET'S CREATE" },
    { text: "SOMETHING" },
    { text: "TOGETHER", span: true },
  ];
  const users = [
    { text: "Get in touch", class: " custom-font text-lg lg:text-2xl mb-1" },
    {
      text: "hello@eirikandreas.io",
      link: "mailto:hello@eirikandreas.io",
      class: "text-base lg:text-xl",
    },
  ];

  const socialLinks = [
    { text: "Social links", class: " custom-font text-lg lg:text-2xl mb-1" },
    {
      text: "Instagram",
      link: "https://www.instagram.com/eirikandreasb",
      class: "text-base lg:text-xl",
    },
    {
      text: "Facebook",
      link: "https://www.facebook.com/eirikandreasb",
      class: "text-base lg:text-xl",
    },
    {
      text: "LinkedIn",
      link: "https://www.linkedin.com/in/eirik-andreas-bergsmark",
      class: "text-base lg:text-xl",
    },
  ];

  return (
    <motion.div
      className="contact-background overflow-hidden"
      variants={wrapper}
      initial="hidden"
      animate="show"
      exit="exit"
    >
      <Background />
      <div className="absolute bottom-10 z-10 sm:bottom-[110px] left-0 right-0 max-w-full">
        <div className="transition-all duration-300 w-full container mx-auto items-start flex flex-wrap justify-between px-[20px] md:px-[70px] layout-max">
          <div className="w-full container mx-auto items-start flex flex-wrap justify-between layout-max">
            <motion.div
              variants={revealVariants}
              initial="hidden"
              animate="visible"
              className="flex flex-col md:flex-row  w-full overflow-hidden layout-max text-black"
            >
              <div className="flex flex-col">
                {heroTitle && (
                  <motion.h1
                    variants={container}
                    initial="hidden"
                    animate="visible"
                    className="tmax-w-sm tracking-tight font-bold text-2xl leading-tight font-primary text-primary-text lg:text-[96px] lg:leading-tight lg:max-w-[800] custom-font text-size-full overflow-hidden hero-container"
                  >
                    {heroTitle.map((item, i) => (
                      <div
                        key={`text-${item.text}`}
                        style={{ overflow: "hidden", display: "inline-block" }}
                      >
                        <motion.div
                          variants={listItem}
                          initial="hidden"
                          animate="visible"
                          exit="exit"
                          style={{ display: "inline-block" }}
                        >
                          {item.span ? (
                            <span className="gradient-background text-mask">
                              {item.text}
                            </span>
                          ) : (
                            item.text
                          )}
                        </motion.div>
                      </div>
                    ))}
                  </motion.h1>
                )}

                <div className="flex items-start mt-4 lg:mt-8 mb-8 w-[80%] md:w-[40%] overflow-hidden">
                  {users && (
                    <motion.div
                      variants={container}
                      initial="hidden"
                      animate="visible"
                      className="flex flex-col text-secondary-text text-md tracking-tight leading-snug overflow-hidden"
                    >
                      {users.map((item, i) => (
                        <div
                          key={`contact-${item.text}`}
                          style={{
                            overflow: "hidden",
                            display: "inline-block",
                          }}
                        >
                          <motion.div
                            variants={listItem}
                            animate="visible"
                            initial="hidden"
                            exit="exit"
                            style={{ display: "inline-block" }}
                          >
                            {item.link ? (
                              <Link to={item.link} icon appendClass={`${item.class} mb-1`}>
                                {item.text}
                              </Link>
                            ) : (
                              <h2 className={item.class}>{item.text}</h2>
                            )}
                          </motion.div>
                        </div>
                      ))}
                    </motion.div>
                  )}
                </div>

                <div>
                  {socialLinks && (
                    <motion.div
                      variants={container}
                      initial="hidden"
                      animate="visible"
                      className="flex flex-col text-secondary-text text-md tracking-tight leading-snug sm:text-xl lg:text-2xl overflow-hidden"
                    >
                      {socialLinks.map((item, i) => (
                        <div
                          key={`socialLinks-${item.text}`}
                          style={{
                            overflow: "hidden",
                            display: "inline-block",
                          }}
                        >
                          <motion.div
                            variants={listItem}
                            animate="visible"
                            initial="hidden"
                            exit="exit"
                            style={{ display: "inline-block" }}
                          >
                            {item.link ? (
                              <Link to={item.link} icon appendClass={`${item.class} mb-1`}>
                                {item.text}
                              </Link>
                            ) : (
                              <h2 className={item.class}>{item.text}</h2>
                            )}
                          </motion.div>
                        </div>
                      ))}
                    </motion.div>
                  )}
                </div>
              </div>
            </motion.div>
          </div>
        </div>
      </div>
    </motion.div>
  );
};

export default Contact;
