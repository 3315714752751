import { useEffect, useContext } from "react";
import FrontPage from "../pages/FrontPage";
import Contact from "../pages/Contact";
import { Route, Routes, useLocation } from "react-router-dom";
import { AnimatePresence } from "framer-motion";
import { MyContext } from "../provider";

function AnimatedRoutes() {
  const location = useLocation();
  const { handleLocation } = useContext(MyContext);

  useEffect(() => {
    handleLocation(location);
  }, [location]);

  return (
    <AnimatePresence mode="wait">
      <Routes location={location} key={location.pathname}>
        <Route path="/" element={<FrontPage />} />
        <Route path="/contact" element={<Contact />} />
      </Routes>
    </AnimatePresence>
  );
}

export default AnimatedRoutes;
