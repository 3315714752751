import React, { createContext, useEffect, useState } from 'react';

export const MyContext = createContext();

export function MyProvider(props) {
  const [selectedLink, setSelectedLink] = useState(0);
  const [routerLocation, setRouterLocation] = useState({})


  const handleLocation = (location) => {
    setRouterLocation(location)
  }

  const handleLinkClick = (i) => {
    setSelectedLink(i);
  };

  useEffect(() => {
    if(routerLocation.pathname === '/contact') {
      setSelectedLink(1)
    } else {
      setSelectedLink(0)
    }
  },[routerLocation.pathname]);

  return (
    <MyContext.Provider value={{ selectedLink, handleLinkClick, setRouterLocation, routerLocation, handleLocation }}>
      {props.children}
    </MyContext.Provider>
  );
}
