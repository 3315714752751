import { useState, useContext } from "react";
import {
  LayoutGroup,
  motion,
  AnimatePresence,
} from "framer-motion";
import { NavLink, Link } from "react-router-dom";
import { MyContext } from "../../../provider";
import { revealVariantsHeader, imageVariants, logoVariants } from "../../../data/animations"

export const linkData = [
  { title: "Index", route: "/" },
  { title: "Contact", route: "/contact" },
];

const Header = () => {
  const { selectedLink, handleLinkClick } =
    useContext(MyContext);
  const [logoHover, setIsLogoHover] = useState(false);

  return (
    <motion.div
      variants={revealVariantsHeader}
      initial="hidden"
      animate="visible"
      exit="exit"
      className="relative layout-max mx-auto"
    >
      
      <AnimatePresence initial={logoHover}>
        <motion.div
          variants={imageVariants}
          animate={logoHover ? "hover" : "initial"}
          className="profile-picture-container"
        >
          <img
            src="img/profile/profile.png"
            alt=""
            className="profile-picture z-10"
          />
        </motion.div>
      </AnimatePresence>
      <header
        className={`fixed top-0 left-0 right-0 pt-[30px] md:pt-[100px] z-40 header header-blend`}
      >
        <div className="relative w-full container mx-auto px-[20px] md:px-[70px] items-start flex flex-wrap justify-between layout-max">
          <div
            className="relative flex items-center"
            onMouseEnter={() => setIsLogoHover(true)}
            onMouseLeave={() => setIsLogoHover(false)}
          >
            <AnimatePresence initial={logoHover}>
              <motion.div
                variants={logoVariants}
                animate={logoHover ? "hover" : "initial"}
              >
                <div className="mr-4 text-sm lg:text-xl flex flex-col font-medium logo-name z-20">
                  <Link to="/">
                    <div>Eirik Andreas</div>
                    <div>
                      <span>UI / UX Designer</span>
                    </div>
                  </Link>
                </div>
              </motion.div>
            </AnimatePresence>
          </div>
          <div className="header-links-wrapper relative text-sm lg:text-xl">
            <LayoutGroup transition={{ duration: 0.5 }}>
              <ol className="header-links-container">
                {linkData.map((item, i) => (
                  <motion.li
                    key={`header-links-${item.title}`}
                    className={`header-link ${
                      i === selectedLink && "selected"
                    }`}
                    animate
                  >
                    {i === selectedLink && (
                      <motion.div
                        className="header-link-underline"
                        layoutId="underline"
                      />
                    )}
                    <NavLink
                      to={item.route}
                      className={({ isActive, isPending }) => isActive ? "text-[#ffffff]" : ""}

                      onClick={() => handleLinkClick(i)}

                    >
                      {item.title}
                    </NavLink>
                  </motion.li>
                ))}
              </ol>
            </LayoutGroup>
          </div>
        </div>
      </header>
    </motion.div>
  );
};

export default Header;
