import { motion } from "framer-motion";
import { revealVariants, container, listItem } from "../../data/animations";

const FrontPage = () => {
  const heroTitle = [
    { text: "INTERFACES" },
    { text: "EXPERIENCES", extra: " &", span: true },
    { text: "ACCESSIBILITY" },
  ];
  const text = [
    "Interaction designer based in Oslo, with",
    " a passion for creating awesome user",
    " experiences and accessible design.",
  ];

  return (
    <div className="absolute bottom-10 sm:bottom-[158px] left-0 right-0 max-w-full">
      <div className="transition-all duration-300 w-full container mx-auto items-start flex flex-wrap justify-between px-[20px] md:px-[70px] layout-max">
        <div className="w-full container mx-auto items-start flex flex-wrap justify-between layout-max">
          <motion.div
            variants={revealVariants}
            initial="hidden"
            animate="visible"
            className="flex flex-col w-full overflow-hidden hero-container"
          >
            {heroTitle && (
              <motion.h1
                variants={container}
                initial="hidden"
                animate="visible"
                className="tmax-w-sm tracking-tight font-bold text-2xl leading-tight font-primary text-primary-text lg:text-[96px] lg:leading-tight lg:max-w-[800] custom-font text-size-full overflow-hidden"
              >
                {heroTitle.map((item, i) => (
                  <div
                    key={`hero-${item.text}-${i}`}
                    style={{ overflow: "hidden", display: "inline-block" }}
                  >
                    <motion.div
                      variants={listItem}
                      initial="hidden"
                      animate="visible"
                      exit="exit"
                      style={{ display: "inline-block" }}
                    >
                      {item.span ? (
                        <>
                          <span className="gradient-background text-mask">
                            {item.text}
                          </span>
                          {item.extra && <>{item.extra}</>}
                        </>
                      ) : (
                        item.text
                      )}
                    </motion.div>
                  </div>
                ))}
              </motion.h1>
            )}

            <div className="mb-8 description-container overflow-hidden">
              {text && (
                <motion.div
                  variants={container}
                  initial="hidden"
                  animate="visible"
                  className="flex flex-col text-secondary-text text-md tracking-tight leading-snug mt-4 lg:mt-8 sm:text-xl lg:text-2xl overflow-hidden"
                >
                  {text.map((item, i) => (
                    <div
                      key={`sub-${item}`}
                      style={{ overflow: "hidden", display: "inline-block" }}
                    >
                      <motion.p
                        variants={listItem}
                        initial="hidden"
                        animate="visible"
                        exit="exit"
                        style={{ display: "inline-block" }}
                      >
                        {item}
                      </motion.p>
                    </div>
                  ))}
                </motion.div>
              )}
            </div>
          </motion.div>
        </div>
      </div>
    </div>
  );
};

export default FrontPage;
