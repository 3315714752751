import { Link as RouterLink } from "react-router-dom";

const Link = ({ to, appendClass, children, icon }) => {
  return (
    <RouterLink to={to} className={`${appendClass ? appendClass : ''} text-xl port-link`}>
      <span className="mb-[2px]">
      {children}
      </span>

      {icon && (
        <span className="icon-arrow-link ml-[6px] leading-3"/>
      )}
     
    </RouterLink>
  );
};

export default Link;
