import "./App.css";
import Header from "./components/layout/Header";
import AnimatedRoutes from "./components/AnimatedRoutes";
import { BrowserRouter as Router } from "react-router-dom";
import { MyProvider } from "./provider";

function App() {
  console.log("Hello there!");
  return (
    <div className="App">
      <MyProvider>
        <div className="transition-all ease-in-out duration-100">
          <Router>
            <Header />
            <AnimatedRoutes />
          </Router>
        </div>
      </MyProvider>
    </div>
  );
}

export default App;
