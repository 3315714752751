import Marquee from "react-fast-marquee";

const Background = () => {
  return (
    <div className="contact-marquee-cont overflow-hidden">
      <div className="text-black custom-font uppercase">
        <Marquee direction="right" speed="12">
          <div className="marquee-text-size">
            Graphic Design Interaction Design User Experiences Ux Design Coffee
            Accessability&nbsp;
          </div>
        </Marquee>
        <Marquee direction="left" speed="20">
          <div className="marquee-text-size">
            Ux Design <span className="photography-text-3 text-mask">Ph</span>
            <span class="photography-text-4 text-mask">ot</span>
            <span className="photography-text-5 text-mask">og</span>
            <span className="photography-text-2 text-mask">gr</span>
            <span className="photography-text-6 text-mask">ap</span>
            <span className="photography-text-1 text-mask">hy</span>
            Graphic Design User Experiences Interaction Design
            Accessability&nbsp;
          </div>
        </Marquee>
        <Marquee direction="right" speed="6">
          <div className="marquee-text-size">
            Interaction Design Accessability Ux Design Graphic Design Music User
            Experiences&nbsp;
          </div>
        </Marquee>
      </div>
    </div>
  );
};

export default Background;
